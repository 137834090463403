import React, { useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './i18n';
import "./css/ColorStyles.css";
import "./css/BackgroundColor.css";
import $ from "jquery";


import { useLocation, useNavigationType ,Routes, Route } from "react-router-dom";



import Home from "./pages/Home";
import Agvting from "./pages/Agvting";
import AgvtingDetails from "./pages/AgvtingDetails";
import AgvtingIns from "./pages/AgvtingIns";





import { CSSTransition, TransitionGroup } from 'react-transition-group';


const langsType = process.env.REACT_APP_API_LANG;


function App() {

  const location = useLocation();
  const navigationType = useNavigationType();
 
  const clickLocation = () => {
    window.open('https://www.google.com/intl/ko_kr/chrome/', '_blank');
  }

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Chrome인지 확인
    const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
    if( !isChrome){
      alert('Please run it in a browser that supports MetaMask.');
    }
    
  }, []);
//   

  return (
      <div>
        <div className="lodingBarBack"></div>
        <div className="lodingBar">
          <img src={process.env.PUBLIC_URL + '/imgaes/lodingGif.gif'} />
        </div>
        
        <div className='chromeAlert'>
         <div className='chromTtl'>Please open the browser in Chrome!</div>
         <div className='chromBtn' onClick={(e)=>{clickLocation()}}>Download Chrome</div>
        </div>



        
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/Agvting" element={<Agvting />} />
          <Route path="/AgvtingDetails" element={<AgvtingDetails />} />
          <Route path="/AgvtingIns" element={<AgvtingIns />} />
          
        </Routes>


        <div className='bottomVersion'>Web version {process.env.REACT_APP_API_VER}</div>
      </div>
  );
}

export default App;
