import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../css/Agvting.css";
import $ from "jquery";
import { useTranslation, Trans } from 'react-i18next'; 
import TopMenu from "./TopMenu";
import { ethers } from 'ethers';
import Web3 from 'web3';


import * as securetyAs from './securityUtill';
import * as blockDataUtill from './blockDataUtill'; 

const Agvting = ({handle}) => {
  const [address, setAddress] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loginCheck, setLoginCheck] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [konetBalOf, setKonetBalOf] = useState("0");
  const [statkingBalOf, setStatkingBalOf] = useState("0");
  const [vKonetBalOf, setVKonetBalOf] = useState("0");
  const [avtingList, setAvtingList] = useState([]);
  

  const [modal1, setModal1] = useState({
      onTtl : null
  ,   onCloseBool : null
  ,   onContentTxt : ""
  });

  const startEndDateFormat = (_date) => {
    const date = new Date(Number( _date) * 1000); 
    const formattedDate = dataForm( date);
    return formattedDate;

  }

  const fullTwoDataMinUnForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"" + month + "" + day + "" + hour + "" + minute + "" + second;
  }

  const dataForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024
    const lastTwoDigits = fullYear.toString().slice(-2);  

    return lastTwoDigits + '-' + month + '-' + day;
  }

  const fullDataForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"" + month + "" + day;
  }


  const handleMessageFromChild = (newAddress) => {
    setAddress(newAddress);  // 자식에서 받은 메시지로 상태 업데이트
  };
  const governanceList = async ( address) => {
    let listCnt = await blockDataUtill.getGovernanceList();  
    let listCntNum = Number(listCnt);
    listCntNum = listCntNum - 1;
  
    const pageSize = 20;
    
    let listPus = [];
    try{
      $( ".lodingBarBack, .lodingBar").show(); 
      for (let i = 0; i <= pageSize && listCntNum >= 0; i++, listCntNum--) {
        console.log( "여기인가용");
        const listreturn = await blockDataUtill.getGovernanceListInfo(listCntNum);
        console.log( listreturn);
         
  
        const detailesAddress = listreturn[0];
        const detailInfo = await blockDataUtill.getInfoAll(detailesAddress);
        
        const myGovernence = await blockDataUtill.getMyGovernence( address , listCntNum);
  
        let ft = false;
        if( Number( myGovernence[2]) > 0){
            ft = true;
        }
        const nowdate = new Date(Number( detailInfo[8]) * 1000); 
        const nowfullData = fullTwoDataMinUnForm( nowdate);
  
        const startdate = new Date(Number( detailInfo[5]) * 1000); 
        const startfullData = fullTwoDataMinUnForm( startdate);
  
        const enddate = new Date(Number( detailInfo[6]) * 1000); 
        const endfullData = fullTwoDataMinUnForm( enddate);
        
        let btnTxtReturn = "Failed"; //통과실패
  
        if( nowfullData < startfullData){
          btnTxtReturn = "Registration";
        }
  
        if( nowfullData >= startfullData &&  nowfullData <= endfullData){
          btnTxtReturn = "Voting Start";
        }
  
     
        const balanceC = await blockDataUtill.balanceOfFomatTwo( listreturn[3]);
        
        const balaceNa = Number(balanceC);
        const thirtyPercentValue = balaceNa * ( Number(listreturn[4]) / 100);
  
  
        let _agreeCount = await blockDataUtill.balanceOfFomatTwo( listreturn[5]);
        let _disagreeCount = await blockDataUtill.balanceOfFomatTwo( listreturn[6]);
        let _totCount = Number(_agreeCount) + Number( _disagreeCount);

        if(endfullData < nowfullData && (thirtyPercentValue <= _totCount) && (Number( _agreeCount) > Number( _disagreeCount))){
          btnTxtReturn = "Passed";
        }
   
        if (detailInfo != undefined) {
          let pushData = {
            num : listCntNum,
            title: detailInfo[0],
            content: detailInfo[1],
            insetUser: detailInfo[2],
            insertDate: detailInfo[3],
            executed: detailInfo[4],
            endDate: detailInfo[6],
            startDate: detailInfo[5],
            proposalData: detailInfo[7],
            nowDate: detailInfo[8],
            myGovernence : ft ,
            btnTxt : btnTxtReturn,
            detailesAddress : detailesAddress
            
          };
  
          listPus.push(pushData);
        }
      }
      setAvtingList(listPus);  // 데이터 상태 업데이트
    } catch (error) {

    }finally{
      $( ".lodingBarBack, .lodingBar").hide(); 
    }
    

  
    
  }
  

  const initView = async (userAccount) => {
    //내가 가지고 있는 코넷 갯수
    const konetBalanceOf = await blockDataUtill.konetBalanceOf( userAccount);
    setKonetBalOf( konetBalanceOf);

    //스테이킹한 코넷 갯수
    const memberInfoVal = await blockDataUtill.getStakingBal( userAccount);
    const balance = await blockDataUtill.balanceOfFomat( memberInfoVal);
    setStatkingBalOf(balance);

      //현재 vkonet 갯수
     const vKonetVal = await blockDataUtill.getvKonetBal( userAccount);
     const vKonetbalance = await blockDataUtill.balanceOfFomat( vKonetVal);
  

    setVKonetBalOf( vKonetbalance);
  }


  const clickLocation = ( _url) => {
    if(address == null || address == ""){
      alert("Please connect the wallet.");
      return;
      
    }
  
    navigate( "/" + _url);
  }

  const clickUrl = ( _objs) => {
    console.log( _objs);
    navigate("/AgvtingDetails", {
      state: {
         indexParam : _objs.num
      ,  detailesAddress :  _objs.detailesAddress
      }
    });

  }


  useEffect(() => {
      

      return () => {
          console.log('컴포넌트가 언마운트될 때 실행됩니다.');
      };
  }, []); 
  useEffect(() => {
    if( address != null && address != ""){
      initView( address); 
    }

    governanceList( address);





    return () => {
        console.log('컴포넌트가 언마운트될 때 실행됩니다.');
    };
  }, [address]); 


  return (
    <div className="HomeLst">
        <TopMenu menuType={2} handle={handleMessageFromChild} />
        <div className='maxWidthRap meMarHei'>
          <div className='meTpTtl fc_000000' style={{textAlign : "center"}}>Agenda Voting List</div>
          <div className='meTpTtlPower fc_000000 bg_blue_FFFFFF'>My KGT Power<br /><span>{vKonetBalOf} KGT</span></div>
          <div className='powerListRap'>
              <div className='agvinsertBtn fc_000000'  onClick={(e)=>{clickLocation( "AgvtingIns")}}>register</div>
          </div>
          <div className='powerListRap bg_blue_FFFFFF'>

          {avtingList.map((item,index) => (
            <div className='powerListLiRap' onClick={(e)=>{clickUrl( item)}}>
              <div className='powerListLi powerListLiTpMar powerListLiLtPadding fc_000000'>{item.num}</div>
              <div className='powerListLi powerListLiBorder fc_000000' style={{ marginTop : "0px"}}><div className='votingTypeBtn' id={"btn_border_" +item.num}>{item.btnTxt}</div></div>
              <div className='powerListLi powerListLiTpMar powerListLiBorder fc_000000'>{item.title}</div>
              <div className='powerListRtRap'  style={{ marginTop : "0px"}}>
                  <div className='powerListLi powerListLiTpMar  fc_000000'>{startEndDateFormat( item.startDate)} ~ {startEndDateFormat( item.endDate)}</div>
                  <div className='powerListLi powerListLiTpMar  fc_000000'>
                  {item.myGovernence ? "voted" : "No voted"}
                  </div>
                  <div style={{clear : 'both'}}></div>
              </div>
              

              <div style={{clear : "both"}}></div>
            </div>

          ))}
     
          </div>


        </div>

        <div className='bottomHeiRap'></div>

    </div>
  );
};

export default Agvting;