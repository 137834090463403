import React, { useState,useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../css/AgvtingIns.css";
import $ from "jquery";
import { useTranslation, Trans } from 'react-i18next'; 
import TopMenu from "./TopMenu";
import { ethers } from 'ethers';
import Web3 from 'web3';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


import * as securetyAs from './securityUtill';
import * as blockDataUtill from './blockDataUtill'; 

const AgvtingIns = ({handle}) => {
  const [address, setAddress] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [votingType, setVotingType] = useState(0);
  const [date, setDate] = useState();
  const [dateTwo, setDateTwo] = useState();
  const calendarRef = useRef();
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [twoisCalendarVisible, setTwoisCalendarVisible] = useState(false);

  const [konetBalOf, setKonetBalOf] = useState("0");
  const [statkingBalOf, setStatkingBalOf] = useState("0");
  const [vKonetBalOf, setVKonetBalOf] = useState("0");

  const [agepVkonetBal, setAgepVkonetBal] = useState("0");
  const [minimumtoken, setMinimumtoken] = useState("0");

  const [insertKonetBal, setInsertKonetBal] = useState("0");

  //


  const dataForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  }
  
  const fullTwoDataMinUnForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"" + month + "" + day + "" + hour + "" + minute + "" + second;
  }
  
  const handleDateChange = (newDate) => {
    setDate(newDate);

    const date = new Date( newDate);
    const testcall = dataForm( date);

    const timestampMilliseconds = date.getTime();
    const timestampSeconds = Math.floor(timestampMilliseconds / 1000);

    console.log(timestampSeconds);
    
    setIsCalendarVisible(false);
  };

  const calanderOpen = () => {
    setIsCalendarVisible(true);
  }

  const handleDateChange2 = (newDate) => {
    //newDate 
    console.log(newDate);

    const date = new Date( newDate);
    const testcall = dataForm( date);


    const timestampMilliseconds = date.getTime();
    const timestampSeconds = Math.floor(timestampMilliseconds / 1000);
    

    // Date 객체로 변환
    const tsdate = new Date(timestampMilliseconds);

    setDateTwo(newDate);
    setTwoisCalendarVisible(false);
  };

  const calanderOpen2 = () => {

    setTwoisCalendarVisible(true);

    
  }

  const initView = async (userAccount) => {
    //내가 가지고 있는 코넷 갯수
    const konetBalanceOf = await blockDataUtill.konetBalanceOf( userAccount);
    setKonetBalOf( konetBalanceOf);

    //스테이킹한 코넷 갯수
    const memberInfoVal = await blockDataUtill.getStakingBal( userAccount);
    const balance = await blockDataUtill.balanceOfFomat( memberInfoVal);
    setStatkingBalOf(balance);

      //현재 vkonet 갯수
     const vKonetVal = await blockDataUtill.getvKonetBal( userAccount);
     const vKonetbalance = await blockDataUtill.balanceOfFomat( vKonetVal);

    setVKonetBalOf( vKonetbalance);

    const minimumtoken = await blockDataUtill.minimumTokenRequired();
    const minimumtokenFomat = await blockDataUtill.balanceOfFomat( minimumtoken);
    setMinimumtoken( minimumtokenFomat);


    const proposalCostCnt = await blockDataUtill.proposalCost();
    const proposalCostCntForm = await blockDataUtill.balanceOfFomatTwo( proposalCostCnt);
    

    setInsertKonetBal( proposalCostCntForm);

  }
  
  

  const [modal1, setModal1] = useState({
      onTtl : null
  ,   onCloseBool : null
  ,   onContentTxt : ""
  });
  const insertCall = async () =>{
    const _date = new Date( date);
    const timestampMilliseconds = _date.getTime();
    const timestampSeconds = Math.floor(timestampMilliseconds / 1000);

    const _dateTwo = new Date( dateTwo);
    const timestampMillisecondsTwo = _dateTwo.getTime();
    const timestampSecondsTwo = Math.floor(timestampMillisecondsTwo / 1000);

    const _title = $( "#title").val();
    const _content = $( "#content").val();

    const res = await blockDataUtill.insertGovernance(_title, _content, "", timestampSecondsTwo, timestampSeconds , insertKonetBal);
    if( res == "ok"){
      navigate( "/Agvting");
    }
  
  }

  const handleMessageFromChild =  (newAddress) => {
    setAddress(newAddress);  // 자식에서 받은 메시지로 상태 업데이트
  };

  const clickSender = async () => {
    if( $( "#startDate").val() == ""){
      alert('Please select the voting start date.');
      return;  
    }
    if( $( "#endDate").val() == ""){
      alert('Please select the voting end date.');
      return;  
    }

    if( $( "#title").val() == ""){
      alert('Please enter the title.');
      return;  
    }

    if( $( "#content").val() == ""){
      alert('Please enter the content.');
      return;  
    }

    const nowDate = await blockDataUtill.nowDateCall();
    const nowdate = new Date(Number( nowDate) * 1000); 
    const nowfullData = Number(fullTwoDataMinUnForm( nowdate));

    const startDate = Number(fullTwoDataMinUnForm( dateTwo));
    const endDate = Number(fullTwoDataMinUnForm( date));

    
    if( endDate < startDate){
      alert('The voting end date cannot be earlier than the start date.');
      return;
    }
    console.log(endDate - startDate);

    if( (endDate - startDate) < 7000000){
      alert("The voting duration must be longer than 7 days.");
      return;
    }

    //현재 vkonet 갯수
    const vKonetVal = await blockDataUtill.getvKonetBal( address);
    let vKonetbalance = await blockDataUtill.balanceOfFomatTwo( vKonetVal);
    vKonetbalance = Number(vKonetbalance);

    const minimumtoken = await blockDataUtill.minimumTokenRequired();
    let minimumtokenFomat = await blockDataUtill.balanceOfFomatTwo( minimumtoken);
    minimumtokenFomat = Number( minimumtokenFomat);

    if(minimumtokenFomat > vKonetbalance){
      alert('가지고 계신 vKonet이 부족합니다. 의제등록 최소 vKonet 수량 : ' + minimumtokenFomat);
      return;

    }

    //스마트컨트렉트 내용이 들어가면 됨
    insertCall();
    
    // 
    



  }



  useEffect(() => {

      return () => {
          console.log('컴포넌트가 언마운트될 때 실행됩니다.');
      };
  }, []); 
  useEffect(() => {
    
    if( address != null && address != ""){
      initView( address);
    }


    return () => {
        console.log('컴포넌트가 언마운트될 때 실행됩니다.');
    };
  }, [address]); 


  return (
    <div className="HomeLst">
        <TopMenu menuType={2} handle={handleMessageFromChild} />
          
 
        <div className='maxWidthRap meMarHei'>
          <div className='meTpTtl fc_000000' style={{textAlign : "center"}}>Propose governance agenda</div>
          <div className='votingDateCall fc_000000'>
              <div className='votingDateLt'>
                  <div className='MyOldCnt bg_blue_FFFFFF'>Konet owned<br />{konetBalOf} Konet</div>
                  <div style={{clear : "both"}}></div>
              </div>
              <div className='votingDateLt votingDateLtMar'>
                  <div className='MyOldCnt bg_blue_FFFFFF'>KGT owned<br />{vKonetBalOf} KGT</div>
                  <div style={{clear : "both"}}></div>
              </div>


              <div style={{clear : "both"}}></div>
              
              <div className='votingRageRap bg_blue_FFFFFF'>
                <div className='votingRageSubTtl' >Vote start date</div>
                <div className='votingRageSubCalander' style={{marginTop : "10px"}}> 
                  <div className='votingRageSubInput'>
                  <input 
                    type="text"
                    id="startDate"
                    value={dateTwo}
                    onClick={(e)=>{calanderOpen2()}}
                    
                    readOnly
                    className="date-input" />
                  </div>
                 
                </div>
                <div>
                {twoisCalendarVisible && (
                <Calendar
                    onChange={handleDateChange2}
                    locale="en-US"
                    value={dateTwo}
                  />
                )}

                </div>

                <div className='votingRageSubTtl' style={{marginTop : "10px"}}>Vote end date</div>
                <div className='votingRageSubCalander' style={{marginTop : "10px"}}> 
                  <div className='votingRageSubInput'>
                  <input
                    type="text"
                    id="endDate"
                    value={date}
                    onClick={(e)=>{calanderOpen()}}
                    
                    readOnly
                    className="date-input" />
                  </div>
                 
                </div>
                <div>
                {isCalendarVisible && (
                <Calendar
                    onChange={handleDateChange}
                    locale="en-US"
                    value={date}
                  />
                )}

                </div>

                <div className='votingRageSubTtl' style={{marginTop : "10px"}}>
                    title
                </div>
                <div className='votingRageInputRap'>
                  <input type="text" className='votingRageSubInput' id='title' />
                </div>

                <div className='votingRageSubTtl' style={{marginTop : "10px"}}>
                  Content
                </div>
                <div className='votingRageTextArea' style={{marginTop : "10px"}}>
                  <textarea id='content'></textarea>
                </div>

                <div className='botingBtLine bg_blue_5B6BAF'></div>

                <div className='konetViewRap'>
                    <div>
                      <div className='konetViewLi'>owned konet</div>
                      <div className='konetViewLiSub'>{konetBalOf} Konet</div>
                      <div className='konetViewLi'>owned KGT</div>
                      <div className='konetViewLiSub'>{vKonetBalOf} KGT</div>
                      <div className='konetViewLi'>Agenda Requirements KGT</div>
                      <div className='konetViewLiSub'>{minimumtoken} KGT</div>
                      <div style={{clear : "both"}}></div>
                    </div>

                </div>

                <div className='stakingButton bg_blue_C4CFFF fc_0029E2' onClick={(e)=>{clickSender()}} >Propose</div>


              </div>


          </div>

         


        </div>

        <div className='bottomHeiRap'></div>

    </div>
  );
};

export default AgvtingIns;