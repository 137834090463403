import axios from 'axios';
import $ from "jquery";
import { ethers } from 'ethers';
import Web3 from 'web3';


const networkUrl = "https://api.kon-wallet.com";


//0xb19250a5dc1b26181f26163d09db4d5ed750df4c
const stakingContra = "0x08ed9d46213e311314d3373b525a74464ece7973";

//0x8cb231bbec0a153018eff0dd9f0f3fa22e6d312b
const managerContra = "0x3e3fab1df2cc4fb5607d99363fa404259dfc95ab";

//0x7f99cd7dda8dcf0fb5e6d41d620f60e8e292ace2
const trajliContra = "0x4e6967f9ace4ee87cc1bd030b36d676a830175a9";





// export const getMemberInfo = async (userAccount) => {

//    const provider = new ethers.BrowserProvider(window.ethereum);
//    const signer = await provider.getSigner();
//    const contract = new ethers.Contract(contractAddress, contractABI, signer);

//    try {
//        // 스마트 계약 함수 호출
//        const member = await contract.getMemberInfo( userAccount);

//        return member;
  
//    } catch (error) {
//        console.error("Error fetching member info:", error);
//    } finally {
      
//    }
   
// }

export const chainIdCheck = async () => {
   
   const chainId = await window.ethereum.request({ method: 'eth_chainId' });

   return chainId;
}


export const metaMaskError = async ( err) =>{

   if( err.info.error.code == 4001){

      return;
   }

    alert('sumit error\n'+ err);
    console.error("Error fetching member info:", err);
}


export const getStakingBal = async (userAccount) => {


   const stakingBalABI = [{
      "inputs":[
         {
            "name":"user",
            "type":"address"
         }
      ],
      "name":"getBalance",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }];


      
   const konetStakingAddress = stakingContra;
  

   
   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(konetStakingAddress, stakingBalABI, signer);
   console.log(contract);
   const networkId = provider.networkVersion;
   console.log( "###########");
   console.log( networkId);

   //0xb19250a5dc1b26181f26163d09db4d5ed750df4c
   
   try {
       // 스마트 계약 함수 호출
       const member = await contract.getBalance( userAccount);
 

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}


export const getvKonetBal = async (userAccount) => {
   const stakingBalABI = [{
      "inputs":[
         {
            "name":"account",
            "type":"address"
         }
      ],
      "name":"balanceOf",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }];
   const konetStakingAddress = stakingContra;
   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(konetStakingAddress, stakingBalABI, signer);

   
   try {
       // 스마트 계약 함수 호출
       const member = await contract.balanceOf( userAccount);


       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}


export const getFeeCal = async (userAccount) => {


   const stakingBalABI = [{
      "inputs":[
         {
             "name":"_doposit_amount",
              "type":"uint256"
         }
      ],
      "name":"getFeeCal",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }];
   const konetStakingAddress = trajliContra;
   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(konetStakingAddress, stakingBalABI, signer);

   try {
       // 스마트 계약 함수 호출
       const member = await contract.getFeeCal( userAccount);

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}



export const getMemberInfo = async (userAccount) => {

   const stakingBalABI = [
      {
         "inputs": [
           {

             "name": "",
             "type": "address"
           }
         ],
         "name": "members",
         "outputs": [
           {

             "name": "balance",
             "type": "uint256"
           },
           {

             "name": "exists",
             "type": "bool"
           },
           {

             "name": "index",
             "type": "uint256"
           },
           {

             "name": "reg_time",
             "type": "uint256"
           },
           {

             "name": "update_time",
             "type": "uint256"
           }
         ],
         "stateMutability": "view",
         "type": "function"
       }
   ];
   const konetStakingAddress = stakingContra;
   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(konetStakingAddress, stakingBalABI, signer);

   try {


       // 스마트 계약 함수 호출
       const member = await contract.members( userAccount);


       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}





export const balanceOfFomat = async (balanceOf) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      return "0";
   }
   
    let konetBalOf = ethers.formatEther(balanceOf);
    let num = Number(konetBalOf) ;
    let result = num.toFixed(4);
    let resultTot = result.split(".");
    let strReturn = Number(resultTot[0]).toLocaleString() + "." + resultTot[1];
    
    return strReturn;

}

export const balanceOfFomatTwo = async (balanceOf) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      return "0";
   }

    let konetBalOf = ethers.formatEther(balanceOf);
    let num = Number(konetBalOf) ;
    let result = num.toFixed(4);

    
    return result;

}

export const balanceOfFomatTh = async (balanceOf) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      return "0";
   }

    let num = Number( balanceOf) ;
    let result = num.toFixed(4);
    let resultTot = result.split(".");
    let strReturn = Number(resultTot[0]).toLocaleString() + "." + resultTot[1];
    
    return strReturn;

}

export const konetBalanceOf = async (userAccount) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      return "0";
   }
   

    const provider = new ethers.BrowserProvider(window.ethereum); 
    const userBalance = await provider.getBalance(userAccount);
    let konetBalOf = ethers.formatEther(userBalance);
    let num = Number(konetBalOf) ;
    let result = num.toFixed(4);
    let resultTot = result.split(".");
    let strReturn = Number(resultTot[0]).toLocaleString() + "." + resultTot[1];
    
    return strReturn;
    
}


export const konetBal = async (userAccount) => {

    const provider = new ethers.BrowserProvider(window.ethereum); 
    const userBalance = await provider.getBalance(userAccount);
    
    //let konetBalOf = ethers.formatEther(userBalance);
    return ethers.formatEther(userBalance);   
}

export const withDraw = async (withBal) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      alert('The network is different. Please check.');
      return;
   }
   
   $( ".lodingBarBack, .lodingBar").show();
       // MetaMask를 통한 provider 생성
       try {
         const stakingBalABI = [{
            "inputs":[
               {
                  "name":"_amount",
                  "type":"uint256"
               }
            ],
            "name":"withdraw",
            "outputs":[
   
            ],
            "stateMutability":"nonpayable",
            "type":"function"
         }];
         const withdrawwStakingAddress = stakingContra;
         const provider = new ethers.BrowserProvider(window.ethereum);
    
         // 토큰 금액을 18자리 소수로 변환
         const amount = ethers.parseUnits(withBal.toString(), 18);
     
         // 서명자(signer) 객체 가져오기
         const signer = await provider.getSigner();
         console.log("Signer Address:", await signer.getAddress());  // 서명자의 주소 출력
     
         // 스마트 컨트랙트 객체 생성 (서명자와 연결)
         const contract = new ethers.Contract(withdrawwStakingAddress, stakingBalABI, signer);
     

           // deposit 함수 호출 (tokenAddress와 amount 전달)
           const tx = await contract.withdraw(amount);
           console.log("Transaction sent:", tx.hash);
   
           // 트랜잭션이 완료될 때까지 대기
           const receipt = await tx.wait();
           
           alert('Withdrawal has been completed.');

           // 트랜잭션 영수증 반환
           return receipt;
       } catch (error) {
           metaMaskError( error);
           return null;  // 실패 시 null 반환
       }finally{
         $( ".lodingBarBack, .lodingBar").hide();
       }


}





export const konetStaking = async (userAccount, balance, tokenAddress) => {
   const checkId = await chainIdCheck();


   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      alert('The network is different. Please check.');
      return;
   }


   $( ".lodingBarBack, .lodingBar").show(); 
   try {
      //function deposit(uint256 _amount) public payable
      const stakingContAddress = stakingContra;

      const stakingBalABI = [{
         "inputs":[
            {
               "name":"_amount",
               "type":"uint256"
            }
         ],
         "name":"deposit",
         "outputs":[
         
         ],
         "stateMutability":"payable",
         "type":"function"
      }];

    // MetaMask를 통한 provider 생성
    const provider = new ethers.BrowserProvider(window.ethereum);
    
    // 토큰 금액을 18자리 소수로 변환
    const amount = ethers.parseUnits(balance.toString(), 18);

    // 서명자(signer) 객체 가져오기
    const signer = await provider.getSigner();
    console.log("Signer Address:", await signer.getAddress());  // 서명자의 주소 출력

    // 스마트 컨트랙트 객체 생성 (서명자와 연결)
    const contract = new ethers.Contract(stakingContAddress, stakingBalABI, signer);

   
        // deposit 함수 호출 (tokenAddress와 amount 전달)
        const tx = await contract.deposit(amount,{value: amount});
        console.log("Transaction sent:", tx.hash);

        // 트랜잭션이 완료될 때까지 대기
        const receipt = await tx.wait();
        
        alert('Staking has been completed.');

        // 트랜잭션 영수증 반환
        return receipt;
    } catch (error) {
        metaMaskError( error);
        return null;  // 실패 시 null 반환
    }finally{
      $( ".lodingBarBack, .lodingBar").hide();
    }



};



// export const getGovernanceList = async (userAccount) => {
//    const stakingBalABI = [{
//       "inputs":[
        
//       ],
//       "name":"total_cnt",
//       "outputs":[
//          {
//             "name":"",
//             "type":"uint256"
//          }
//       ],
//       "stateMutability":"view",
//       "type":"function"
//    }];
//    const konetStakingAddress = managerContra;

//    const provider = new ethers.BrowserProvider(window.ethereum);


//    const signer = await provider.getSigner();


//    const contract = new ethers.Contract(konetStakingAddress, stakingBalABI, signer);

   
//    try {
//        // 스마트 계약 함수 호출
//        const member = await contract.total_cnt();

//        return member;
  
//    } catch (error) {
//        console.error("Error fetching member info:", error);
//    } finally {
      
//    }
   
// }

export const getGovernanceList = async (userAccount) => {   // 로컬 Geth 노드 RPC URL (기본적으로 8545 포트 사용)
   const web3 = new Web3( networkUrl);  // 로컬에서 실행된 Geth 노드에 연결

   // 스마트 컨트랙트 주소와 ABI 설정
   const contractAddress = managerContra;  // 스마트 컨트랙트 주소
   const contractABI = [{
      "inputs":[
        
      ],
      "name":"total_cnt",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }];

   const contract = new web3.eth.Contract(contractABI, contractAddress);

   try {
      const totalSupply = await contract.methods.total_cnt().call();
      return totalSupply;
   } catch (error) {
      console.error('Error:', error);
   }



}




export const getGovernanceListInfo = async (_count) => {
   const web3 = new Web3( networkUrl);  // 로컬에서 실행된 Geth 노드에 연결


   const stakingBalABI = [{
      "inputs":[
         {
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"registeredProposals",
      "outputs":[
         {
            "name":"proposalAddress",
            "type":"address"
         }
      ,  {
         "name":"proposalAuthorAddress",
         "type":"address"
         }
      ,  {
         "name":"createdAt",
         "type":"uint256"
         }
      ,  {
         "name":"total_balance_kgt",
         "type":"uint256"
         }
      ,  {
         "name":"minimumVotesRequired",
         "type":"uint256"
         }
      ,  {
         "name":"agreeCount",
         "type":"uint256"
         }  
      ,  {
         "name":"disagreeCount",
         "type":"uint256"
         }  
      ,  {
         "name":"end_date",
         "type":"uint256"
         }  
      ,  {
         "name":"start_date",
         "type":"uint256"
         }  

      ],
      "stateMutability":"view",
      "type":"function"
   }];


   try {
      const konetStakingAddress = managerContra;
      const contract = new web3.eth.Contract(stakingBalABI, konetStakingAddress);
      console.log( "여기가 안되는거양?");
       // 스마트 계약 함수 호출
       const member = await  contract.methods.registeredProposals( _count).call( );
       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}


// export const getGovernanceListInfo = async (_count) => {



//    const stakingBalABI = [{
//       "inputs":[
//          {
//             "name":"",
//             "type":"uint256"
//          }
//       ],
//       "name":"registeredProposals",
//       "outputs":[
//          {
//             "name":"proposalAddress",
//             "type":"address"
//          }
//       ,  {
//          "name":"proposalAuthorAddress",
//          "type":"address"
//          }
//       ,  {
//          "name":"createdAt",
//          "type":"uint256"
//          }
//       ,  {
//          "name":"total_balance_kgt",
//          "type":"uint256"
//          }
//       ,  {
//          "name":"minimumVotesRequired",
//          "type":"uint256"
//          }
//       ,  {
//          "name":"agreeCount",
//          "type":"uint256"
//          }  
//       ,  {
//          "name":"disagreeCount",
//          "type":"uint256"
//          }  
//       ,  {
//          "name":"end_date",
//          "type":"uint256"
//          }  
//       ,  {
//          "name":"start_date",
//          "type":"uint256"
//          }  

//       ],
//       "stateMutability":"view",
//       "type":"function"
//    }];
//    const konetStakingAddress = managerContra;
//    const provider = new ethers.BrowserProvider(window.ethereum);
//    const signer = await provider.getSigner();
//    const contract = new ethers.Contract(konetStakingAddress, stakingBalABI, signer);

   
//    try {
//        // 스마트 계약 함수 호출
//        const member = await contract.registeredProposals( _count);

//        return member;
  
//    } catch (error) {
//        console.error("Error fetching member info:", error);
//    } finally {
      
//    }
   
// }





// export const getInfoAll = async (_contractAddress) => {
//    const stakingBalABI = [{
//       "inputs":[

//       ],
//       "name":"getInfoAll",
//       "outputs":[
//          {
//             "name":"title",
//             "type":"string"
//          }
//      ,   {
//             "name":"description",
//             "type":"string"
//          }
//       ,  {
//          "name":"proposer",
//          "type":"address"
//       }
//       ,  {
//          "name":"createdAt",
//          "type":"uint256"
//       }  
//       ,  {

//          "name": "executed",
//          "type": "bool"
//       }
//       ,  {

//          "name": "end_date",
//          "type": "uint256"
//       }
//       ,  {

//          "name": "start_date",
//          "type": "uint256"
//       }
//       ,  {

//          "name": "proposalData",
//          "type": "string"
//       }
//       ,  {

//          "name": "",
//          "type": "uint256"
//       }



//       ],
//       "stateMutability":"view",
//       "type":"function"
//    }];

//    const provider = new ethers.BrowserProvider(window.ethereum);
//    const signer = await provider.getSigner();
//    const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);
   
   
//    try {
//        // 스마트 계약 함수 호출
//        const member = await contract.getInfoAll();

//        return member;
  
//    } catch (error) {
//        console.error("Error fetching member info:", error);
//    } finally {
      
//    }
   
// }


export const getInfoAll = async (_contractAddress) => {
   const web3 = new Web3( networkUrl);  // 로컬에서 실행된 Geth 노드에 연결

   const stakingBalABI = [{
      "inputs":[

      ],
      "name":"getInfoAll",
      "outputs":[
         {
            "name":"title",
            "type":"string"
         }
     ,   {
            "name":"description",
            "type":"string"
         }
      ,  {
         "name":"proposer",
         "type":"address"
      }
      ,  {
         "name":"createdAt",
         "type":"uint256"
      }  
      ,  {

         "name": "executed",
         "type": "bool"
      }
      ,  {

         "name": "end_date",
         "type": "uint256"
      }
      ,  {

         "name": "start_date",
         "type": "uint256"
      }
      ,  {

         "name": "proposalData",
         "type": "string"
      }
      ,  {

         "name": "",
         "type": "uint256"
      }



      ],
      "stateMutability":"view",
      "type":"function"
   }];

   try {

      const contract = new web3.eth.Contract(stakingBalABI, _contractAddress);
      const member = await contract.methods.getInfoAll().call();

      return member;
 
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}



export const getMyGovernence = async (_address, index) => {
   const web3 = new Web3( networkUrl);  // 로컬에서 실행된 Geth 노드에 연결

   const stakingBalABI = [{
      "inputs":[
         {
            "name":"",
            "type":"address"
         }
         ,  {
            "name":"",
            "type":"uint256"
         }
      ],
      "name":"myVoteInfoDetail",
      "outputs":[
         {
            "name":"agreeCount",
            "type":"uint256"
         }
         ,        {
            "name":"disagreeCount",
            "type":"uint256"
         }
         ,        {
            "name":"totalCount",
            "type":"uint256"
         }

      ],
      "stateMutability":"view",
      "type":"function"
   }];

   const _contractAddress = managerContra;
   
   try {
      console.log( "전달값 확인");
      console.log(_address, index);
      if(_address == null || _address == ""){

         _address = _contractAddress;
      }
     

      const contract = new web3.eth.Contract(stakingBalABI, _contractAddress);
      const member = await contract.methods.myVoteInfoDetail(_address, index).call( );

      return member;
   
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}



export const insertGovernance = async (_title, _description, _proposalData, _start_date, _end_date, insertKonetBal) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      alert('The network is different. Please check.');
      return;
   }



   const stakingBalABI = [{
      "inputs":[
         {
         "name":"_title",
         "type":"string"
         }
      ,  {
         "name":"_description",
         "type":"string"
         }
      ,  {
         "name":"_proposalData",
         "type":"string"
         }
      ,  {
         "name":"_start_date",
         "type":"uint256"
         }
      ,  {
         "name":"_end_date",
         "type":"uint256"
         }
     
      ],
      "name":"proposal",
      "outputs":[
     
      ],
      "stateMutability":"payable",
      "type":"function"
   }];
   const _contractAddress = managerContra;
   

   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);
      // 토큰 금액을 18자리 소수로 변환

      
      const amount = ethers.parseUnits( insertKonetBal.toString(), 18);
   
   
   try {
      $( ".lodingBarBack, .lodingBar").show(); 

      const member = await contract.proposal( _title, _description, _proposalData, _start_date, _end_date,{value: amount});
      //const member = await contract.proposal( _title, _description, _proposalData, _start_date, _end_date);
      console.log( "????????????????????????????");
      console.log(member);

      // 트랜잭션이 완료될 때까지 대기
      const receipt = await member.wait();

        
      alert('Transfer complete');
      
        // 트랜잭션 영수증 반환
       return "ok";
  
   } catch (error) {
       await metaMaskError( error);
     
   } finally {
      $( ".lodingBarBack, .lodingBar").hide(); 
   }
   
}


export const insertVote = async (_index, isAgree, votingBal) => {
   const checkId = await chainIdCheck();
   if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      alert('The network is different. Please check.');
      return;
   }


   $( ".lodingBarBack, .lodingBar").show(); 
   const voteABI = [{
      "inputs": [
        {
          "name": "index",
          "type": "uint256"
        },
        {
          "name": "isAgree",
          "type": "bool"
        },
        {
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "vote",
      "outputs": [
        
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    }];

   const _contractAddress = managerContra;


   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract( _contractAddress, voteABI, signer);
   const amount = ethers.parseUnits(votingBal.toString(), 18);

   try {
       
       const member = await contract.vote( (_index), isAgree, amount);
        // 트랜잭션이 완료될 때까지 대기
       const receipt = await member.wait();
       alert( "The vote has been completed.");

        // 트랜잭션 영수증 반환
       return receipt;
  
   } catch (error) {
       metaMaskError( error);
      
   } finally {
      $( ".lodingBarBack, .lodingBar").hide(); 
   }
   
}

export const minimumTokenRequired = async () => {
   const stakingBalABI = [{
      "inputs": [
      ],
      "name": "MINIMUM_TOKEN_REQUIRED",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }];

   const _contractAddress = managerContra;
   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);


   try {
       // 스마트 계약 함수 호출
       const member = await contract.MINIMUM_TOKEN_REQUIRED();

       
       

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }

}

export const proposalCost = async () => {
   const stakingBalABI = [{
      "inputs": [
      ],
      "name": "PROPOSAL_COST",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }];

   const _contractAddress = managerContra;
   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);


   try {
       // 스마트 계약 함수 호출
       const member = await contract.PROPOSAL_COST();

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }

}




export const availableQuantity = async (index, _address) => {
   const stakingBalABI = [{
      "inputs":[
         {
            "name":"index",
            "type":"uint256"
         }
         ,  {
            "name":"proposalAddr",
            "type":"address"
         }
      ],
      "name":"availableQuantity",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }
         ,        {
            "name":"",
            "type":"uint256"
         }
         ,        {
            "name":"",
            "type":"uint256"
         }
         ,        {
            "name":"",
            "type":"uint256"
         }

      ],
      "stateMutability":"view",
      "type":"function"
   }];

   const _contractAddress = managerContra;

   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);
   console.log( contract);

   try {
       console.log(index,_address);

       // 스마트 계약 함수 호출
       const member = await contract.availableQuantity( index,_address);
       console.log(member);
       

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}


export const withDrawRockUpCheck = async (_address) => {
   const stakingBalABI = [{
      "inputs":[
         {
            "name":"sender",
            "type":"address"
         }  
   
      ],
      "name":"getVoteLockEndDate",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }
         ,         {
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }];

   const _contractAddress = stakingContra;

   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);
   console.log( contract);

   try {
       // 스마트 계약 함수 호출
       const member = await contract.getVoteLockEndDate( _address);

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}

export const nowDateCall = async () => {
   const stakingBalABI = [{
      "inputs":[
   
      ],
      "name":"getNowDate",
      "outputs":[
         {
            "name":"",
            "type":"uint256"
         }

      ],
      "stateMutability":"view",
      "type":"function"
   }];

   const _contractAddress = stakingContra;

   const provider = new ethers.BrowserProvider(window.ethereum);
   const signer = await provider.getSigner();
   const contract = new ethers.Contract(_contractAddress, stakingBalABI, signer);
   console.log( contract);

   try {
       // 스마트 계약 함수 호출
       const member = await contract.getNowDate();

       return member;
  
   } catch (error) {
       console.error("Error fetching member info:", error);
   } finally {
      
   }
   
}